import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="preregister"
export default class extends Controller {
  static targets = ["dob", "amountDisabled", "amountHidden"];
  static values = {
    fee: Number,
    feeOnline: Number,
  };

  connect() {
    let online = document.querySelector(
      'input[name="preregistration[online]"]:checked'
    );
    let position_type_cd = document.querySelector(
      'input[name="preregistration[position_type_cd]"]:checked'
    );
    let member_type_cd = document.querySelector(
      'input[name="preregistration[member_type_cd]"]:checked'
    );

    this.populateAmount(online.value);

    // 전공의(position_type_cd === 3) 또는 만70세이상(member_type_cd === 3)인 경우 금액을 0으로 설정
    if (position_type_cd && position_type_cd.value === "3") {
      this.updateAmount(member_type_cd ? +member_type_cd.value : null, 3);
    } else if (member_type_cd && member_type_cd.value === "3") {
      this.updateAmount(3);
    }
  }

  // 참가방법 : 오프라인신청(false), 온라인신청(true)
  setFee(e) {
    // this.populateAmount(e.target.value);
    document
      .querySelectorAll('input[name="preregistration[position_type_cd]"]')
      .forEach((el) => {
        el.checked = false;
      });
    document
      .querySelectorAll('input[name="preregistration[member_type_cd]"]')
      .forEach((el) => {
        el.checked = false;
      });
    this.amountDisabledTarget.value = "";
    this.amountHiddenTarget.value = "";
    console.log(e.target.value);
  }

  populateAmount(online_value) {
    let member_type_cd = document.querySelector(
      'input[name="preregistration[member_type_cd]"]:checked'
    );
    this.amount = online_value === "true" ? this.feeOnlineValue : this.feeValue;

    if (member_type_cd !== null) this.checkOver70(+member_type_cd.value);
    // console.log(this.amount);
  }

  // 근무형태 : 개원원, 봉직의, 전공의, 기타
  handleClick(e) {
    let member_code = +e.target.value;
    // uncheck all member_type_cd
    document
      .querySelectorAll('input[name="preregistration[position_type_cd]"]')
      .forEach((el) => {
        el.checked = false;
      });
    this.updateAmount(member_code);
  }

  // 회원구분 : 대한외과회원, 비회원 및 타과회원, 만 70세이상
  checkOver70(member_code) {
    if (member_code === 3) {
      this.dobTarget.classList.remove("hidden");
      this.updateAmount(member_code);
    } else {
      this.dobTarget.classList.add("hidden");
      this.updateAmount(member_code);
    }
  }

  handleClickPositionType(e) {
    let position_type_cd = +e.target.value;
    const member_type_cd = document.querySelector(
      'input[name="preregistration[member_type_cd]"]:checked'
    );

    if (position_type_cd === 3) {
      document.getElementById(
        "preregistration_member_type_cd_2"
      ).checked = true;
    }

    this.updateAmount(
      member_type_cd ? +member_type_cd.value : null,
      position_type_cd
    );
  }

  // 금액 업데이트 함수
  updateAmount(member_code, position_type_cd = null) {
    const isZeroFee = member_code === 3 || position_type_cd === 3;

    this.amountDisabledTarget.value = isZeroFee ? "0" : this.amount;
    this.amountHiddenTarget.value = isZeroFee ? "0" : this.amount;
  }
}
